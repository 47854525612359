// src/components/landing-page/ImageGrid.tsx
import React from 'react';
import imagesArray from '../constants/servicesImageHero';


const ImageGrid: React.FC = () => {
    return (
        <div className="grid grid-cols-6">
            {imagesArray.map((img, idx) => (
                <picture key={`img-${img.id}-${idx}`} className="w-16 h-16">
                    <source media="(min-width: 1024px)" srcSet={img.desktop} />
                    <source media="(min-width: 768px)" srcSet={img.tablet} />
                    <img
                        src={img.mobile}
                        alt={`Imagen de servicio ${img.id}`}
                        className="w-full h-full object-cover"
                    />
                </picture>
            ))}
        </div>
    );
};

export default ImageGrid;
