import React, { useState } from 'react';
import {GoogleAuthButton, GoogleAuthSuccess} from "../../components/auth/GoogleAuthButton/GoogleAuthButton";
import authService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Importa i18n para cambiar el idioma
import NextStepGuard, { NextStepGuardRouteString } from '../../guards/NextStepGuard';
import routesConfig from '../../config/routes.config';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    const response = localStorage.getItem('authResponse');
    console.log('Response:', response);
    if (response) {
      const nextStep = JSON.parse(response)?.nextStep;
      console.log('Next step:', nextStep);
      if (nextStep || nextStep === 0) {
        const reditectTo = NextStepGuardRouteString();
        console.log('Redirecting to:', reditectTo);
        navigate(reditectTo);
      }
      return;
    }

    try {
      const response = await authService.login(email, password);
      console.log('Login successful:', response);

      localStorage.setItem('accessToken', response?.accessToken);
      localStorage.setItem('refreshToken', response?.refreshToken);
      localStorage.setItem('nextStep', response?.nextStep?.toString());
      localStorage.setItem('authResponse', JSON.stringify(response));
      const reditectTo = NextStepGuardRouteString();
      navigate(reditectTo);
    } catch (err: any) {
      console.error('Login failed:', err);
      setError('Las credenciales son incorrectas o el usuario no está autorizado.');
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left side with image */}
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center"
        style={{ backgroundImage: 'url(/assets/img/login-side-img.png)' }}
      />
      {/* Right side with form */}
      <div className="flex flex-col items-center justify-center md:w-1/3 w-full p-8">
        <h1 className="text-2xl font-bold mb-6">{t('welcome')}</h1>
        {/* Language Change Buttons */}
        <div className="flex justify-end space-x-4 mb-4">
          <button
            onClick={() => i18n.changeLanguage('es')}
            className="text-sm text-gray-500 hover:underline"
          >
            Español
          </button>
          <button
            onClick={() => i18n.changeLanguage('en')}
            className="text-sm text-gray-500 hover:underline"
          >
            English
          </button>
        </div>
        <form onSubmit={handleSubmit} className="w-full max-w-sm">
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('email')}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-500"
              placeholder={t('email') || ''}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {t('password')}
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-500"
              placeholder={t('password') || ''}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="text-right mt-2">
              <a href="#" className="text-sm text-blue-500 hover:underline">
                {t('forgotPassword')}
              </a>
            </div>
          </div>
          {error && (
            <div className="mb-4 text-sm text-red-600">
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full py-3 bg-red-500 text-white font-bold rounded-full hover:bg-red-600 transition-colors"
          >
            {t('login')}
          </button>
        </form>
        <p className="mt-6 text-sm text-gray-600">
          {t('createAccount')}?{' '}
          <a href={routesConfig.createAccount} className="text-blue-500 hover:underline">
            {t('createAccount')}
          </a>
        </p>
        <div className="my-4 flex items-center justify-center w-full">
          <span className="text-sm text-gray-500">{t('orLoginWith')}</span>
        </div>
        <div className="flex flex-col gap-4 w-full max-w-sm">
          <GoogleAuthButton  />
          <button className="flex items-center justify-center py-2 border border-blue-600 rounded-full shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700">
            <img src="/assets/img/facebook-icon.png" alt="Facebook" className="h-4 w-4 mr-2" />
            {t('facebook')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
