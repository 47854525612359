import React, { useState } from "react";

import CategoriesSlider from "../components/CategoriesSlider";
import ServicesHero from "../components/ServicesHero";
import MarketplacePreview from "../components/MarketplaceHero";
import JoinCommunity from "../components/JoinCommunityHero";
import AppDownloadHero from "../components/AppDownloadHero";
import ContactHero from "../components/ContactHero";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useNavigate } from "react-router-dom";

const topServices = [
  { name: "Corte de uñas", price: "$5,42k", provider: "Peluquería damaris" },
  { name: "Guardería perros", price: "$5,42k", provider: "Pets miguel" },
  { name: "Consulta Medico G.", price: "$5,42k", provider: "La soma" },
];

const LandingProfessionalPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const filteredServices = topServices.filter((service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <BaseLayout>
      <div>
          <div className="text-center mt-4">
            <input
              type="text"
              placeholder="Buscar servicios..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="px-4 py-2 border rounded-lg w-full max-w-md"
            />
          </div>

        {searchTerm && filteredServices.length > 0 && (
          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6">
              Resultados de la búsqueda
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {filteredServices.map((service, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
                >
                  <h3 className="font-semibold mb-2">{service.name}</h3>
                  <p className="text-lg font-bold mb-2">{service.price}</p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {service.provider}
                  </p>
                  <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg">
                    Enviar Solicitud
                  </button>
                </div>
              ))}
            </div>
          </section>
        )}
          <CategoriesSlider />
          <div className="text-center mt-4">
            <button
              onClick={() =>
                navigate("/categories", { state: { isDashboard: false } })
              }
              className="bg-primary text-white px-4 py-2 rounded-lg"
            >
              Ver todas
            </button>
          </div>

          <ServicesHero />
          <h2 className="text-3xl font-bold mb-6">
            Top De Servicios Mas Comprados
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {topServices.map((service, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
              >
                <h3 className="font-semibold mb-2">{service.name}</h3>
                <p className="text-lg font-bold mb-2">{service.price}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {service.provider}
                </p>
                <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg">
                  Enviar Solicitud
                </button>
              </div>
            ))}
          </div>
        <MarketplacePreview />
        <JoinCommunity onJoinClick={() => navigate("/join")} />
        <AppDownloadHero />
        <ContactHero />
      </div>
    </BaseLayout>
  );
};

export default LandingProfessionalPage;
