import React from 'react';
import CategoriesSlider from '../components/CategoriesSlider';
import { BaseLayout } from '../components/layout/BaseLayout';

const CategoriesPage: React.FC = () => {
  return (
    <BaseLayout>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Nuestras Categorías</h1>
      <CategoriesSlider isDashboard={false} />
    </div>
    </BaseLayout>
  );
};

export default CategoriesPage;