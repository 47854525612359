interface JoinCommunityProps {
  onJoinClick: () => void;
}

const JoinCommunity: React.FC<JoinCommunityProps> = ({ onJoinClick }) => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-between py-16 px-4 md:px-8">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h2 className="text-3xl font-bold mb-4">
          ¿Ofreces servicios? Únete a nuestra comunidad y lleva tu negocio al
          siguiente nivel
        </h2>
        <p className="text-gray-600 dark:text-white mb-6">Comenzar tu camino es gratis!</p>
        <button
          onClick={onJoinClick}
          className="bg-red-400 hover:bg-red-500 text-white px-6 py-3 rounded-full transition-colors"
        >
          Únete a nuestra comunidad
        </button>
      </div>
      <div className="md:w-1/2 relative">
        <img
          src="/assets/img/red-woman.png"
          alt="Professional"
          className="w-full max-w-md mx-auto"
        />
        {/* Floating avatars */}
        <div className="absolute top-0 right-0">
          <img
            src="/assets/img/Ellipse-1.png"
            alt="Member 1"
            className="rounded-full"
          />
        </div>
        <div className="absolute bottom-1/2 left-0">
          <img
            src="/assets/img/Ellipse-2.png"
            alt="Member 2"
            className="rounded-full"
          />
        </div>
        <div className="absolute top-1/2 -left-1/4">
          <img
            src="/assets/img/Ellipse-3.png"
            alt="Member 3"
            className="rounded-full"
          />
        </div>
        <div className="absolute -bottom-1/4 right-1/4">
          <img
            src="/assets/img/Ellipse-3.png"
            alt="Member 3"
            className="rounded-full"
          />
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
