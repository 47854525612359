import CustomRightArrow from './custom-right-arrow.svg';

export const ButtonRightArrow = () => {
  return (
    <img
      src={CustomRightArrow}
      alt={'Login'}
      style={{
        position: 'absolute',
        right: '10px',
      }}
    />
  );
};