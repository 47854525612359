import React from 'react';

const CamiloPage: React.FC = () => {
    return (
        <div>
            <h1>Welcome to CamiloPage</h1>
            <p>This is the CamiloPage component.</p>
        </div>
    );
};

export default CamiloPage;