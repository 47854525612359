import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useServiceStore from '../store/useServiceStore';
import { BaseLayout } from '../components/layout/BaseLayout';
import routesConfig from '../config/routes.config';

const CategoryServicesPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const category = location.state?.category;
  const { fetchServices, filterServicesByCategory, isLoading, error } = useServiceStore();
  const services = filterServicesByCategory(category.name);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleClick = (serviceId: string) => {
    navigate(routesConfig.serviceForm.replace(':id', serviceId));
  };

  if (!category) {
    return <div className="text-center py-4">No category selected</div>;
  }

  if (isLoading) {
    return <div className="text-center py-4">Loading services...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  }

  return (
    <BaseLayout>
      <>
      <h1 className="text-3xl font-bold mb-6">Servicios de {category.name} que marcan la diferencia</h1>
      <img src={category.imageUrl} alt={category.name} className="w-full h-64 object-cover rounded-md mb-6" />

      <div className="mb-12">
        <h2 className="font-bold mb-2">¿No encuentras lo que necesitas?</h2>
        <p>No te preocupes! cuéntanos que servicio estas buscando, encontraremos empresas que te puedan ayudar y actualizaremos ese servicio a nuestro portafolio.</p>
        <input
          type="text"
          placeholder="¿Qué servicio necesitas?"
          className="px-4 py-2 border rounded-lg w-full max-w-md mt-4"
        />
        <div className="mt-4">
          <p>Si quieres que te avisemos cuando tengamos este servicio en nuestro portafolio ingresa tu correo a continuación:</p>
          <div className="flex mt-2">
            <input
              type="email"
              placeholder="Ingresa tu correo electrónico"
              className="px-4 py-2 border rounded-lg w-full max-w-md"
            />
            <button className="bg-primary text-white px-4 py-2 rounded-lg ml-2">ENVIAR</button>
          </div>
        </div>
      </div>

      <div className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Todos los servicios en un solo lugar!</h2>
        <h3 className="text-xl font-semibold mb-4">Elige el servicio que necesitas</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services.map((service:any) => (
            <div
              key={service.serviceId}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
              onClick={() => handleClick(service.serviceId)}
            >
              <h3 className="font-semibold mb-2">{service.name}</h3>
              <p className="text-lg font-bold mb-2">{service.price}</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{service.provider}</p>
              <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg">Enviar Solicitud</button>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Si no estás seguro siempre puedes buscar tu servicio directamente</h2>
        <input
          type="text"
          placeholder="¿Qué servicio necesitas?"
          className="px-4 py-2 border rounded-lg w-full max-w-md"
        />
      </div>
      </>
    </BaseLayout>
  );
};

export default CategoryServicesPage;