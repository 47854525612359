import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import authService from '../services/auth.service';
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

const CreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = () => {
    navigate('/login');
  };

  const handleGoogleLoginSuccess = async (response: any, userType: string) => {
    try {
      const googleToken = response.credential;
      // Llama a la API para autenticar con Google y obtener los tokens
      const loginResponse = await authService.googleLogin(googleToken, userType);
      
      console.log('Login successful:', response);

      localStorage.setItem('accessToken', loginResponse?.accessToken);
      localStorage.setItem('refreshToken', loginResponse?.refreshToken);
      localStorage.setItem('nextStep', loginResponse?.nextStep?.toString());
      localStorage.setItem('authResponse', JSON.stringify(loginResponse));
      const reditectTo = NextStepGuardRouteString();
      navigate(reditectTo);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleContinueWithEmail = () => {
    navigate('/signup');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="bg-white p-6 shadow-lg rounded-lg w-full max-w-md">

        <main className="flex flex-col items-center">
          <h1 className="text-2xl font-bold text-center mb-4">{t('createAccountPage.title')}</h1>
          <p className="text-gray-500 text-center mb-8">{t('createAccountPage.description')}</p>
          <button
            onClick={handleContinueWithEmail}
            className="w-full bg-red-500 text-white py-3 rounded-lg text-lg mb-4"
          >
            {t('createAccountPage.continueWithEmail')}
          </button>
          <SocialButton
            icon="apple"
            text={t('createAccountPage.continueWithApple')}
            backgroundColor="bg-black"
            textColor="text-white"
            onClick={() => { }}
          />
          <SocialButton
            icon="facebook"
            text={t('createAccountPage.continueWithFacebook')}
            backgroundColor="bg-blue-600"
            textColor="text-white"
            onClick={() => { }}
          />
          <GoogleOAuthProvider clientId="5639077876-f9art3bea5adn7suoaq76cb3gr4oruen.apps.googleusercontent.com">
            <div>
              
              <GoogleLogin
                onSuccess={ async response =>  {
                  await handleGoogleLoginSuccess(response, 'PROFESSIONAL');
                }}
                onError={() => {
                  console.error('Error al iniciar sesión con Google');
                }} />
              <div className="mt-8 text-gray-500 text-sm text-center">
                {t('createAccountPage.privacyPolicy')}
              </div>
            </div>
          </GoogleOAuthProvider>
          {/* <GoogleButton
            imagePath="/assets/img/google_logo.png"
            text={t('createAccountPage.continueWithGoogle')}
            backgroundColor="bg-white"
            textColor="text-black"
            borderColor="border-gray-300"
            onClick={() => {}}
          />
          <div className="mt-8 text-gray-500 text-sm text-center">
            {t('createAccountPage.privacyPolicy')}
          </div> */}
        </main>
      </div>
    </div>
  );
};

const SocialButton: React.FC<{
  icon: string;
  text: string;
  backgroundColor: string;
  textColor: string;
  onClick: () => void;
}> = ({ icon, text, backgroundColor, textColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`${backgroundColor} ${textColor} w-full py-3 rounded-lg text-lg flex justify-center items-center mb-4`}
    >
      <i className={`fab fa-${icon} mr-2`}></i>
      {text}
    </button>
  );
};

const GoogleButton: React.FC<{
  imagePath: string;
  text: string;
  backgroundColor: string;
  textColor: string;
  borderColor?: string;
  onClick: () => void;
}> = ({ imagePath, text, backgroundColor, textColor, borderColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`${backgroundColor} ${textColor} w-full py-3 rounded-lg text-lg flex justify-center items-center mb-4 ${borderColor ? `border ${borderColor}` : ''}`}
    >
      <img src={imagePath} alt="Google" className="h-6 mr-2" />
      {text}
    </button>
  );
};

export default CreateAccountPage;
