import { create } from 'zustand';
import { AuthState } from '../types/auth.types';
import authService from '../services/auth.service';


const saveAuthToLocalStorage = (response: any) => {
  localStorage.setItem('accessToken', response?.accessToken);
  localStorage.setItem('refreshToken', response?.refreshToken);
  localStorage.setItem('nextStep', response?.nextStep?.toString());
  localStorage.setItem('authResponse', JSON.stringify(response));
};

const handleAuthResponse = (response: any, set: any) => {
  saveAuthToLocalStorage(response);
  set({
    user: response,
    isAuthenticated: true,
    nextStep: response.nextStep,
    isLoading: false,
  });
};

const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  nextStep: null,

  login: async (email, password) => {
    set({ isLoading: true, error: null });
    try {
      const response = await authService.login(email, password);
      handleAuthResponse(response, set);
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  register: async (email, password, userType) => {
    set({ isLoading: true, error: null });
    try {
      const response = await authService.register({ email, password, userType: userType || 'USER' });
      handleAuthResponse(response, set);
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  verifyCode: async (code, emailOrPhone) => {
    set({ isLoading: true, error: null });
    try {
      const response = await authService.verifyCode(code, emailOrPhone);
      handleAuthResponse(response, set);
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  resendVerificationCode: async () => {
    set({ isLoading: true, error: null });
    try {
      await authService.resendVerificationCode();
      set({ isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  resetPassword: async (email) => {
    set({ isLoading: true, error: null });
    try {
      await authService.resetPassword(email);
      set({ isLoading: false });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('nextStep');
    localStorage.removeItem('authResponse');
    set({ user: null, isAuthenticated: false });
  },
}));

export default useAuthStore;
