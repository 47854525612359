import { Twitter, Instagram, Linkedin } from 'lucide-react';

const ContactHero: React.FC = () => {
  return (
    <section className="py-16 px-4 md:px-8">
      <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img
            src="/assets/img/contact-cellphone.png"
            alt="Contact Information"
            className="w-full max-w-xs mx-auto"
          />
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h2 className="text-2xl font-bold text-red-400 mb-4">CONTACTANOS!</h2>
          <h3 className="text-3xl font-bold mb-6">
            Tu satisfacción es nuestra misión, ¡cuenta con nosotros para lo que
            necesites!
          </h3>
          <p className="text-gray-600 mb-8 dark:text-stone-100">
            Tu satisfacción es nuestra prioridad. Si tienes preguntas,
            comentarios o necesitas soporte, no dudes en contactarnos. Estamos
            ansiosos por escucharte y brindarte la ayuda que necesitas.
            ¡Hablemos y resolvamos juntos cualquier inquietud!
          </p>
          <div className="bg-red-400 text-white p-8 rounded-lg">
            <h4 className="text-xl font-bold mb-4">
              La revolución de los servicios
            </h4>
            <div className="space-y-2">
              <p>Teléfono: +57 305525412</p>
              <p>Email: info@xervix.com</p>
            </div>
            <div className="flex gap-4 mt-4">
              <a href="#" className="hover:opacity-80 transition-opacity">
              <Twitter />
              </a>
              <a href="#" className="hover:opacity-80 transition-opacity">
              <Instagram />
              </a>
              <a href="#" className="hover:opacity-80 transition-opacity">
              <Linkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
