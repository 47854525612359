import React from "react";
import useAuthStore from "../../store/useAuthStore";
import { useThemeStore } from "../../store/useThemeStore";
import { Sun, Moon, CircleUserRound } from "lucide-react";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const isLoggedIn = useAuthStore((state) => state.isAuthenticated);
  const { isDarkMode, toggleDarkMode } = useThemeStore();
  const location = useLocation();

  const getLinkClass = (path: string) => {
    return location.pathname === path
      ? "text-primary dark:text-dark-primary"
      : "text-onSurface dark:text-dark-onSurface";
  };
  return (
    <header className="container mx-auto px-4 py-6 flex justify-between items-center">
      <h1 className="text-center font-serif text-primary dark:text-dark-primary text-3xl font-bold leading-10 tracking-[5.50px]">
        XERVIX
      </h1>
      <nav>
        <ul className="flex space-x-12 text-onSurface dark:text-dark-onSurface font-medium text-sm">
          <li>
            <a href="/" className={getLinkClass("/")}>
              Inicio
            </a>
          </li>
          <li>
            <a
              href="/mis-servicios"
              className={`${getLinkClass("/mis-servicios")} ${
                isLoggedIn ? "" : "text-outline pointer-events-none"
              }`}
            >
              Mis servicios
            </a>
          </li>
          <li>
            <a
              href="/mis-solicitudes"
              className={`${getLinkClass("/mis-solicitudes")} ${
                isLoggedIn ? "" : "text-outline pointer-events-none"
              }`}
            >
              Mis solicitudes
            </a>
          </li>
          <li>
            <a href="/marketplace" className={getLinkClass("/marketplace")}>
              MarketPlace
            </a>
          </li>
          <li>
            <a
              href="/mi-perfil"
              className={`${getLinkClass("/mi-perfil")} ${
                isLoggedIn ? "" : "text-outline pointer-events-none"
              }`}
            >
              Mi Perfil
            </a>
          </li>
        </ul>
      </nav>
      <div className="flex items-center content-center gap-5">
        <div className="flex flex-row gap-2 items-center">
          <p className="text-onSurface dark:text-dark-onSurface font-medium text-sm">
            Iniciar Sesión
          </p>
          <CircleUserRound className="w-8 h-8 text-primary" />
        </div>
        <button
          onClick={toggleDarkMode}
          className="p-2 rounded-full bg-gray-200 dark:bg-gray-700"
        >
          {isDarkMode ? (
            <Sun className="w-5 h-5" />
          ) : (
            <Moon className="w-5 h-5" />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
