import React from 'react';
import ImageGrid from './ImageGrid';

type ServicesHeroProps = {
    title?: string;
    subtitle?: string;
    buttonText?: string;
    onButtonClick?: () => void;
};

const ServicesHero: React.FC<ServicesHeroProps> = ({
    title = "Accede a una amplia gama de servicios en un solo lugar",
    subtitle = "La revolución de los servicios",
    buttonText = "IR A MARKETPLACE",
    onButtonClick = () => {},
}) => {
    return (
        <div className="relative w-full overflow-hidden bg-white dark:bg-transparent border rounded-2xl border-gray-200">
            <div className="absolute top-0 w-full p-8">
                <div className="flex opacity-25 animate-scroll">
                    <ImageGrid />
                    <ImageGrid />
                    <ImageGrid />
                    <ImageGrid />
                </div>
            </div>
            <div className="relative z-10 max-w-4xl mx-auto text-center px-4 py-32 my-20">
                <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    {title}
                </h1>
                <p className="text-xl text-gray-600 dark:text-gray-200 mb-8">
                    {subtitle}
                </p>
                <button
                    onClick={onButtonClick}
                    className="bg-red-400 hover:bg-red-500 text-white font-semibold py-3 px-8 rounded-full transition-colors"
                >
                    {buttonText}
                </button>
            </div>
            <div className="absolute bottom-0 w-full p-8 mt-2 opacity-25">
                <div className="flex animate-scroll-reverse">
                    <ImageGrid />
                    <ImageGrid />
                    <ImageGrid />
                    <ImageGrid />
                </div>
            </div>
        </div>
    );
};

export default ServicesHero;