import { ReactComponent as AppleIcon} from "../assets/mac-os.svg";
import { ReactComponent as GooglePlay } from "../assets/google-play.svg";

const AppDownloadHero: React.FC = () => {
  return (
    <section className="flex flex-col md:flex-row items-center bg-gray-100 dark:bg-gray-800 rounded-lg p-8">
      <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img
            src="/assets/img/download-cellphone.png"
            alt="App Preview"
            className="w-full max-w-xs mx-auto"
          />
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h2 className="text-3xl font-bold mb-4">
            ¡Lleva nuestros servicios a tu bolsillo!
          </h2>
          <h3 className="text-xl mb-6 font-medium">Descarga nuestra App ahora</h3>
          <p className="mb-8">
            Descubre la manera más fácil y rápida de acceder a nuestros
            servicios con nuestra nueva app. Todo lo que necesitas, al alcance
            de tu mano: desde hacer solicitudes, hasta recibir notificaciones en
            tiempo real. Descárgala ahora y disfruta de una experiencia
            personalizada, fluida y segura, diseñada para que tu día a día sea
            más sencillo. ¡No te lo pierdas!
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <button className="flex items-center justify-center bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg transition-colors">
            <AppleIcon className="w-6 h-6 mr-2" fill="white" />
              App Store
            </button>
            <button className="flex items-center justify-center bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg transition-colors">
            <GooglePlay/>
              Google Play
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownloadHero;
