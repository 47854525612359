import service1Desktop from '../assets/images/services/desktop/service-1-desktop.jpg';
import service1Tablet from '../assets/images/services/tablet/service-1-tablet.jpg';
import service1Mobile from '../assets/images/services/mobile/service-1-mobile.jpg';

import service2Desktop from '../assets/images/services/desktop/service-2-desktop.jpg';
import service2Tablet from '../assets/images/services/tablet/service-2-tablet.jpg';
import service2Mobile from '../assets/images/services/mobile/service-2-mobile.jpg';

import service3Desktop from '../assets/images/services/desktop/service-3-desktop.jpg';
import service3Tablet from '../assets/images/services/tablet/service-3-tablet.jpg';
import service3Mobile from '../assets/images/services/mobile/service-3-mobile.jpg';

import service4Desktop from '../assets/images/services/desktop/service-4-desktop.jpg';
import service4Tablet from '../assets/images/services/tablet/service-4-tablet.jpg';
import service4Mobile from '../assets/images/services/mobile/service-4-mobile.jpg';

import service5Desktop from '../assets/images/services/desktop/service-5-desktop.jpg';
import service5Tablet from '../assets/images/services/tablet/service-5-tablet.jpg';
import service5Mobile from '../assets/images/services/mobile/service-5-mobile.jpg';

import service6Desktop from '../assets/images/services/desktop/service-6-desktop.jpg';
import service6Tablet from '../assets/images/services/tablet/service-6-tablet.jpg';
import service6Mobile from '../assets/images/services/mobile/service-6-mobile.jpg';

import service7Desktop from '../assets/images/services/desktop/service-7-desktop.jpg';
import service7Tablet from '../assets/images/services/tablet/service-7-tablet.jpg';
import service7Mobile from '../assets/images/services/mobile/service-7-mobile.jpg';

import service8Desktop from '../assets/images/services/desktop/service-8-desktop.jpg';
import service8Tablet from '../assets/images/services/tablet/service-8-tablet.jpg';
import service8Mobile from '../assets/images/services/mobile/service-8-mobile.jpg';

import service9Desktop from '../assets/images/services/desktop/service-9-desktop.jpg';
import service9Tablet from '../assets/images/services/tablet/service-9-tablet.jpg';
import service9Mobile from '../assets/images/services/mobile/service-9-mobile.jpg';

import service10Desktop from '../assets/images/services/desktop/service-10-desktop.jpg';
import service10Tablet from '../assets/images/services/tablet/service-10-tablet.jpg';
import service10Mobile from '../assets/images/services/mobile/service-10-mobile.jpg';

import service11Desktop from '../assets/images/services/desktop/service-11-desktop.jpg';
import service11Tablet from '../assets/images/services/tablet/service-11-tablet.jpg';
import service11Mobile from '../assets/images/services/mobile/service-11-mobile.jpg';

import service12Desktop from '../assets/images/services/desktop/service-12-desktop.jpg';
import service12Tablet from '../assets/images/services/tablet/service-12-tablet.jpg';
import service12Mobile from '../assets/images/services/mobile/service-12-mobile.jpg';


const serviceImage = [
  { id: 1, desktop: service1Desktop, tablet: service1Tablet, mobile: service1Mobile },
  { id: 2, desktop: service2Desktop, tablet: service2Tablet, mobile: service2Mobile },
  { id: 3, desktop: service3Desktop, tablet: service3Tablet, mobile: service3Mobile },
  { id: 4, desktop: service4Desktop, tablet: service4Tablet, mobile: service4Mobile },
  { id: 5, desktop: service5Desktop, tablet: service5Tablet, mobile: service5Mobile },
  { id: 6, desktop: service6Desktop, tablet: service6Tablet, mobile: service6Mobile },
  { id: 7, desktop: service7Desktop, tablet: service7Tablet, mobile: service7Mobile },
  { id: 8, desktop: service8Desktop, tablet: service8Tablet, mobile: service8Mobile },
  { id: 9, desktop: service9Desktop, tablet: service9Tablet, mobile: service9Mobile },
  { id: 10, desktop: service10Desktop, tablet: service10Tablet, mobile: service10Mobile },
  { id: 11, desktop: service11Desktop, tablet: service11Tablet, mobile: service11Mobile },
  { id: 12, desktop: service12Desktop, tablet: service12Tablet, mobile: service12Mobile },
];

export default serviceImage;
