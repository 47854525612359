import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useThemeStore } from '../../store/useThemeStore';

export const BaseLayout = ({ children }: { children: React.ReactElement }) => {
  const { isDarkMode, toggleDarkMode } = useThemeStore();
  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
        <Header />
        <main className="container mx-auto px-4 py-8">
          {children}
        </main>
        <Footer/>
      </div>
    </div>
  );
};