import React from 'react';

const MarketplacePreview = ({
  title = "Descubre las últimas tendencias en servicios en",
  subtitle = "Xervix Marketplace",
  buttonText = "IR A MARKETPLACE",
  onButtonClick = () => {},
  images = {
    left: '/assets/img/reel-1.png',
    center: '/assets/img/reel-2.png',
    right: '/assets/img/reel-3.png',
  }
}) => {
  return (
    <div className="relative w-full max-w-6xl mx-auto px-4 py-16">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120%] h-[400px] bg-gray-100 rounded-[100%] -z-10" />
      <div className="flex flex-col items-center">
        <div className="relative w-full max-w-3xl mx-auto mb-12">
          <div className="absolute left-0 top-1/2 -translate-y-1/2 w-[300px] transform -rotate-12 z-0">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src={images.left}
                alt="Reel left"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="relative mx-auto w-[300px] z-10">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src={images.center}
                alt="Reel center"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="absolute right-0 top-1/2 -translate-y-1/2 w-[300px] transform rotate-12 z-0">
            <div className="relative w-full pb-[200%] bg-black rounded-[40px] overflow-hidden border-8 border-black">
              <img
                src={images.right}
                alt="Reel right"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
            {title}
          </h2>
          <p className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            {subtitle}
          </p>
          <button
            onClick={onButtonClick}
            className="bg-red-400 hover:bg-red-500 text-white font-semibold py-3 px-8 rounded-full transition-colors"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketplacePreview;