import React, { useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import useCategoryStore from '../store/useCategoryStore';
import { useSliderStore } from '../store/useSliderStore';
import { useNavigate } from 'react-router-dom';
import { Category } from '../types/category.types';

interface Props {
  isDashboard?: boolean;
}

const CategoriesSlider: React.FC<Props> = ({ isDashboard = true }) => {
  const { categories, fetchCategories, isLoading, error } = useCategoryStore();
  const { scroll, startDragging, stopDragging, onDrag } = useSliderStore();
  const sliderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleClick = (category: Category) => {
    navigate(`/category/${category.categoryId}`, { state: { category } });
  };

  if (isLoading) return <div className="text-center py-4">Loading categories...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  if (!categories || categories.data === null) return <div className="text-center py-4">No categories found</div>;

  return (
    <div className="max-w-screen-2xl">
      {isDashboard ? (
        <div className="relative">
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10"
          aria-label="Scroll left"
        >
          <ChevronLeft className="h-6 w-6 text-gray-600" />
        </button>
        <div
          ref={sliderRef}
          className="slider-container flex overflow-x-auto scrollbar-hide space-x-4 p-4 no-scrollbar"
          onMouseDown={startDragging}
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseMove={onDrag}
        >
          {categories.data.map((category: Category) => (
            <div key={category.categoryId} className="flex-none w-72 cursor-pointer" onClick={() => handleClick(category)}>
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden p-2">
                <img src={category.imageUrl} alt={category.name} className="w-full h-64 object-cover" />
                <div className="p-4">
                  <h3 className="font-semibold text-lg">{category.name}</h3>
                  <p className="text-sm text-gray-600">{ category.services.length } Servicios</p>
                  <ul>
                    {category.services.map((service, index) => (
                      <li key={index}>{service.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10"
          aria-label="Scroll right"
        >
          <ChevronRight className="h-6 w-6 text-gray-600" />
        </button>
        </div>
    ) : (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {categories.data.map((category: Category) => (
          <div key={category.categoryId} className="col-span-1 bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden p-4">
            <img src={category.imageUrl} alt={category.name} className="w-full h-64 object-cover rounded-md" />
            <h3 className="mt-2">{category.name}</h3>
            <p>{category.services.length} Servicios</p>
            <ul>
              {category.services.map((service) => (
                <li key={service.serviceId}>{service.name}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )}
    </div>
  );
};

export default CategoriesSlider;