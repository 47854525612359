
import { useGoogleLogin } from '@react-oauth/google';
import { ButtonRightArrow } from '../../common/ButtonRightArrow/ButtonRightArrow';
import GoogleLogo from './custom-google-logo.svg';

export interface GoogleAuthSuccess {
  access_token: string;
  auhuser: string;
  expires_in: number;
  prompt: string;
  scope: string;
  token_type: string;
}

interface Props {
  onSuccess?: (data: GoogleAuthSuccess) => void;
}
export const GoogleAuthButton = (props?: Props) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        if (props?.onSuccess) {
          props.onSuccess(tokenResponse as GoogleAuthSuccess);
        }
      } catch (error) {
        console.log('Error on Google Auth', error);
      }
    },
  });

  return (
    <button
      className="bg-white text-onPrimary dark:bg-dark-background dark:text-onDark-background rounded-md p-2 flex items-center justify-center gap-2 relative"
      onClick={() => login()}
    >
      <img
        src={GoogleLogo}
        alt={'Google Logo'}
        style={{
          position: 'absolute',
          left: '10px',
        }}
      />
      CONTINÚA CON GOOGLE
      <ButtonRightArrow />
    </button>
  );
};